<template>
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="添加管理员"
    ></el-page-header>
    <div class="select-container">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        style="margin-top: 3%"
      >
        <el-form-item label="昵称" prop="userName">
          <el-input style="width: 30%" v-model="ruleForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="cardNo">
          <el-input
            style="width: 30%"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            v-model="ruleForm.cardNo"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password_1">
          <el-input
            style="width: 30%"
            show-password
            v-model="ruleForm.password_1"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="password_2">
          <el-input
            style="width: 30%"
            show-password
            v-model="ruleForm.password_2"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户权限" prop="verify">
          <el-radio v-model="ruleForm.verify" label="1">超级管理员</el-radio>
          <el-radio v-model="ruleForm.verify" label="2">普通管理员</el-radio>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >立即创建</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import secret from "@/utils/secret.js";
export default {
  inject: ["reload"],
  data() {
    return {
      ruleForm: {
        userName: "",
        cardNo: "",
        password_1: "",
        password_2: "",
        verify: "1",
      },
      rules: {
        userName: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          { min: 2, max: 10, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        cardNo: [
          { required: true, message: "请输入用户账号", trigger: "blur" },
          { min: 2, max: 10, message: "长度在 6 到 11 个数字", trigger: "blur" },
        ],
        password_1: [
          { required: true, message: "请设置密码", trigger: "blur" },
          {
            min: 5,
            max: 12,
            message: "长度在 5 到 12 个数字/字母组成的密码",
            trigger: "blur",
          },
        ],
        password_2: [
          { required: true, message: "请设置密码", trigger: "blur" },
          {
            min: 5,
            max: 12,
            message: "长度在 5 到 12 个数字/字母",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.password_1 !== this.ruleForm.password_2) {
            this.$message({
              type: "danger",
              message: "两次密码输入不一致，请确认！",
            });
          } else {
            const ruleForm = this.ruleForm;
            this.userKey = secret.Decrypt(localStorage.getItem("user_key"));
            let userKey = this.userKey;
            axios.post(this.GLOBAL.api + "/register", { ruleForm, userKey }).then((res) => {
              if (res.data.statusCode === 200) {
                this.$message({
                  type: "success",
                  message: "创建成功！",
                });
                this.reload();
              } else if(res.data.statusCode === 501) {
                this.$message({
                  type: "worning",
                  message: "用户已存在，重新输入账号！",
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 0 1%;
}
.box-card {
  width: 100%;
  height: 100%;
}
</style>