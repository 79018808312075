import { render, staticRenderFns } from "./create-admin.vue?vue&type=template&id=45c753fc&scoped=true&"
import script from "./create-admin.vue?vue&type=script&lang=js&"
export * from "./create-admin.vue?vue&type=script&lang=js&"
import style0 from "./create-admin.vue?vue&type=style&index=0&id=45c753fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c753fc",
  null
  
)

export default component.exports